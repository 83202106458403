<template>
  <div class="page-stat2">
    <el-row type="flex" justify="space-between" align="center">
      <el-col>
        <el-page-header content="Статистика 2" title="на главную" @back="$router.push('/')"></el-page-header>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form>
      <el-row>
        <el-col :span="7">
          <el-form-item label="Период">
            <el-date-picker v-model="period" type="daterange" unlink-panels @change="getStat2(period)">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('month')">Месяц</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('week')">Неделя</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('day')">День</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>




    <el-table show-summary  :summary-method="getSummaries" stripe style="width: 100%; margin-bottom: 20px;" :data="stat2.table1">
      <el-table-column
        prop="name">
      </el-table-column>
       <el-table-column
        prop="in"
        label="Вошло"
        >
      </el-table-column>
       <el-table-column
        prop="inwork"
        label="В работе"
        >
      </el-table-column>
       <el-table-column
        prop="success"
        label="Успешно"
        >
      </el-table-column>
      <el-table-column
        prop="wait"
        label="Ожидаем оплату"
        >
      </el-table-column>
      <el-table-column
        prop="conv"
        label="Конверсия"
        >
      </el-table-column>
       <el-table-column
        prop="conv_wait"
        label="Конверсия (ОЖ)"
        >
      </el-table-column>
      <el-table-column
        prop="money"
        label="Денег с канала"
        >
      </el-table-column>
    </el-table>

      <el-table stripe style="width: 100%; margin-bottom: 40px;" :data="stat2.table2">
      <el-table-column
        prop="name">
      </el-table-column>
       <el-table-column
        prop="all"
        label="Все сделки"
        >
      </el-table-column>
       <el-table-column
        prop="perm"
        label="Постоянные"
        >
      </el-table-column>
      <el-table-column
        prop="new"
        label="Новые"
        >
      </el-table-column>
    </el-table>









  
    <el-row :gutter="20">
      <el-col :span="8">
        <apexchart type="pie" :options="chart1" :series="stat2.clients"></apexchart>
      </el-col>  
       <el-col :span="8">
        <apexchart type="pie" :options="chart2" :series="stat2.clients_margin"></apexchart>
      </el-col>  
      <el-col :span="8">
        <apexchart type="line" :options="chart3" :series="stat2.clients_closed"></apexchart> 
      </el-col>
    </el-row>
 
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      period: [moment().subtract(2, "weeks"), moment()],
      chart1: {
        title: {
          text: 'Соотношение постоянных клиентов к новым'
        },
        chart: {
          type: "pie"
        },
        labels: ["Постоянные клиенты", "Новые клиенты"]
      },
      chart2: {
        title: {
          text: 'Соотношение выручки постоянных клиентов к новым'
        },
        chart: {
          type: "pie"
        },
        labels: ["Постоянные клиенты", "Новые клиенты"]
      },
      chart3: {
        title: {
          text: "Закрываемость сделок"
        },
        stroke: {
          width: 3
        },
        chart: {
          type: "line"
        }
      }
    }
  },
  created() {
    this.getStat2(this.period);
  },
  methods: {
    ...mapActions(['getStat2', 'bxUsers']),
    changeDate(type) {
      const periods = {
        month: [moment().subtract('1', 'month').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        week: [moment().subtract('1', 'week').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        day: [moment().subtract('1', 'day').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
      }
      this.getStat2(periods[type])
    },
    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if(index === 0) {
            sums[index] = "Итого";
            console.log(data);  
            return; 
          }
          const values = data.map(item => Number(item[column.property]));
           if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = 'N/A';
          }
        })
        sums[5] = (sums[3]/sums[1]*100).toFixed(2); 
        sums[6] = (sums[4]/sums[1]*100).toFixed(2); 
        return sums; 
    }
  },
  computed: {
    ...mapGetters(["stat2"]),
  }
}
</script>

<style>
.page-stat2 {
  padding: 40px;
}
.el-row {
  margin-bottom: 20px;
}
</style>